

import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import FrontSide from './FrontSide';
import BackSide from './BackSide';
import { connect } from 'react-redux';
import { setSide, hideCanvasBoeder } from '../actions';
import logo from '../Logos/wwl-logo.svg';
import { ReactSVG } from 'react-svg';
import { AddToChart } from './AddToChart';



const Spinner = () => {
  return( <ReactSVG src={logo} className='spinner' />);
};

class Engraving extends Component {
  state = { open: false ,
    hasBackside: false,
    userImages: [],
    loading: false ,
  };

  componentDidMount() {
    if (this.props.back_image) {
      this.setState({ hasBackside : true });
    }
  }


  render() {
    let totalPrice;
    if (this.props.countFS > 0 && this.props.countBS > 0 ) {
      totalPrice = parseFloat(this.props.product.price) + parseFloat(this.props.product.front_side_price) +  parseFloat(this.props.product.back_side_price);
    } else if (this.props.countFS > 0 ) {
      totalPrice = parseFloat(this.props.product.price) + parseFloat(this.props.product.front_side_price);
    } else if (this.props.countBS > 0 ) {
      totalPrice = parseFloat(this.props.product.price) + parseFloat(this.props.product.back_side_price);
    } else totalPrice = parseFloat(this.props.product.price);
    let euro = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })

    return(
      <div className='engravingWrapper'>
        <div className='engravingHeader'>
          <div className='engravingArea'>
          Gravurbereich: {this.props.sideIs === 'front'? `${this.props.product.workArea.front.workAreaWidth} x ${this.props.product.workArea.front.workAreaHeight} cm` : `${this.props.product.workArea.back.workAreaWidth} x ${this.props.product.workArea.back.workAreaHeight} cm` }
          </div>
          <div
            className={this.props.sideIs === 'front'? 'selected' : 'divAsButton' }
            onClick={() => this.props.setSide('front')} >VARIANTE 1</div>
          <div
            className={ this.state.hasBackside ?
              this.props.sideIs !== 'front'? 'selected' : 'divAsButton' : 'disabled'
            }
            onClick={this.state.hasBackside? () => this.props.setSide('back') : null} >VARIANTE 2</div>
        </div>
        <div className='engravingBody' >

          <Popup
            contentStyle={{ background: '#4e4e4e', border: 'none' }}
            open={this.state.loading}
            closeOnDocumentClick={false}
          >
            <div>
              <div style={{ textAlign: 'center' }}>Ihre Bestellung wird bearbeitet</div>
              <Spinner />
            </div>
          </Popup>
          <FrontSide  />
          <BackSide   />
        </div>
        <div className='engravingFooter'>
          <div className='wagenButton'>
            <div className='priceBox'>
              <table>
                <tbody>
                  <tr>
                    <td>Produktpreis: </td><td className='price'>{euro.format(this.props.product.price)}</td>
                  </tr>
                  <tr>
                    <td>Gravur Variante 1: </td><td className='price'>{  this.props.product.front_side_price > 0 ? euro.format(this.props.product.front_side_price) : ''   }</td>
                  </tr>
                  {this.state.hasBackside ?
                    <tr>
                      <td>Gravur Variante 2: </td><td className='price'>{ this.props.product.back_side_price > 0 ? euro.format(this.props.product.back_side_price) : '' }</td>
                    </tr> : null }
                  <tr>
                    <td colSpan='2'>inkl. 19% MwSt. zzgl. <a rel='noreferrer' target='_blank' href='https://www.welovelaser.de/versand/'> Versandkosten </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button className='buttonToChart'  onClick={() => {
            this.setState({ loading: true });
            AddToChart(this.state, this.props, totalPrice);
          }}>
               IN DEN WARENKORB {euro.format(totalPrice)}
            {/* NaN should show error */}
          </button>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  setSide: side => dispatch(setSide(side)),
  hideCanvasBoeder: border => dispatch(hideCanvasBoeder(border)),
});

const mapStateToProps = state => {
  // orginal pics without manipulate pixels
  const { sideIs, orginalPics } = state.optionsReducer;
  const { frontCurrent, backCurrent } = state.currentFigureReducer;
  const { back_image, authtoken } = state.productReducer.product;
  const { product } = state.productReducer;
  const { countFS } = state.frontsideReducer;
  const { countBS } = state.backsideReducer;
  return {
    sideIs, back_image, frontCurrent, backCurrent, orginalPics, authtoken, product, countFS, countBS
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Engraving);
