import React from 'react';
import ReactDOM from 'react-dom';
import App from '../Components/App';
import { Provider } from 'react-redux';
import { store } from '../Components/store';


document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('rootFromRuby');
  const json_data = node.getAttribute('data');
  const front_image = node.getAttribute('front_image');
  const back_image = node.getAttribute('back_image');
  const authtoken = node.getAttribute('authtoken');
  node.remove();
  const data = JSON.parse(json_data);
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const engColor = hexToRgb(data.engraving_color);
  const { title, id, woo_id } = data;
  let {  front_side_price, back_side_price, price } = data;
  const workArea = data.work_area;
  back_side_price =  parseFloat(back_side_price).toFixed(2);
  front_side_price = parseFloat(front_side_price).toFixed(2);
  price = parseFloat(price).toFixed(2);
  const product = {  title,woo_id,id,workArea, engColor, front_image, back_image, authtoken , front_side_price, back_side_price, price };
  store.dispatch({
    type: 'ADD_PRODUCT',
    payload: product
  });
  ReactDOM.render(
    <Provider store={store}>
      <App  />
    </Provider>,
    document.body.appendChild(document.createElement('div')),
  );
});
