import axios from "axios";

export const SET_CONTENT = 'SET_CONTENT';
export const FRONT_CANVAS = 'FRONT_CANVAS';
export const BACK_CANVAS = 'BACK_CANVAS';
export const SET_STYLE = 'SET_STYLE';
export const SET_SIDE = 'SET_SIDE';
export const FRONT_ADD_FIGURE = 'FRONT_ADD_FIGURE';
export const BACK_ADD_FIGURE = 'BACK_ADD_FIGURE';
export const UNDO_FRONT_CANVAS = 'UNDO_FRONT_CANVAS';
export const UNDO_BACK_CANVAS = 'UNDO_BACK_CANVAS';
export const REDO_BACK_CANVAS = 'REDO_BACK_CANVAS';
export const REDO_FRONT_CANVAS = 'REDO_FRONT_CANVAS';
export const HIDE_CANVAS_BORDER = 'HIDE_CANVAS_BORDER';
export const ORGINAL_PICS = 'ORGINAL_PICS';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const objectFS = 'objectFS';
export const objectBS = 'objectBS';
export const UNSELECT_GALLERY_CATEGORY = 'UNSELECT_GALLERY_CATEGORY';
export const LOAD_GALLERY_CATEGORIES = 'LOAD_GALLERY_CATEGORIES';
export const LOAD_GALLERY_CATEGORY_IMAGES = 'LOAD_GALLERY_CATEGORY_IMAGES';
export const SET_GALLERY_SEARCH = 'SET_GALLERY_SEARCH';

export function setSide(side) {
  return {
    type: SET_SIDE,
    payload: side
  };
};


export async function fetchGalleryCategories(dispatch, getState) {
  const response = await axios.get('/library');
  dispatch({type: LOAD_GALLERY_CATEGORIES, payload: response.data});
}

export function fetchGalleryImages(categoryId) {
  return (dispatch, getState) => {
    axios
      .get(`/library/${categoryId}`).then(
        (res) => dispatch({type: LOAD_GALLERY_CATEGORY_IMAGES, payload: res.data}),
        (error)  => {}
      )
  }
}

export function unselectGalleryCategory() {
  return {
    type: UNSELECT_GALLERY_CATEGORY,
  };
}

export function setGallerySearch(query) {
  return {
    type: SET_GALLERY_SEARCH,
    payload: query
  };
}

export function imageOrtext(data) {
  return {
    type: SET_CONTENT,
    payload: data
  };
};

export function addEngraving(data) {
  return {
    type: data[0],
    payload: data[1]
  };
}


export function selectCurrent(current) {
  return {
    type: current[0],
    payload: current[1]
  };
}

export function setStyle(data) {
  return {
    type: SET_STYLE,
    payload: data
  };
}


export function undoFrontSide(data) {
  return {
    type: UNDO_FRONT_CANVAS,
    payload: data
  };
}

export function redoFrontSide(data) {
  return {
    type: REDO_FRONT_CANVAS,
    payload: data
  };
}

export function undoBackSide(data) {
  return {
    type: UNDO_BACK_CANVAS,
    payload: data
  };
}

export function redoBackSide(data) {
  return {
    type: REDO_BACK_CANVAS,
    payload: data
  };
}

// export function showCnavasBorder(data) {
//   return {
//     type: SHOW_CANVAS_BORDER,
//     payload: data
//   };
// }


export function hideCanvasBoeder(data) {
  return {
    type: HIDE_CANVAS_BORDER,
    payload: data
  };
}

export function addoriginalPics(pics) {
  return {
    type: ORGINAL_PICS,
    payload: pics
  };
}

export function acceptTerms(yes) {
  return {
    type: 'ACCEPT_TERMS',
    payload: yes
  };
}

export function countObjectFS(count) {
  return {
    type: 'objectFS',
    payload: count
  };
}

export function countObjectBS(count) {
  return {
    type: 'objectBS',
    payload: count
  };
}
