import React, { Component } from 'react';

class PopUp extends Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          Die Funktion und Darstellung des Konfigurators kann auf Deinem Gerät eingeschränkt sein. <br />
          Bitte nutze für die Gravurgestaltung ein Tablet oder Computer.
        </div>
        <button onClick={this.props.closePopup} className='popup_close'> X </button>
      </div>
    );
  };
}
export default PopUp;
