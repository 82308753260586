/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
import React, { Component }from 'react';
import { connect } from 'react-redux';
import { undoBackSide, redoBackSide, selectCurrent, setStyle,
  countObjectBS, imageOrtext } from '../actions';
import { fabric } from 'fabric';
import { SVGFilter } from './SVGFilter';

class BackSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasHistory: {
        state: [],
        currentStateIndex: -1,
        undoStatus: false,
        redoStatus: false,
        undoFinishedStatus: true,
        redoFinishedStatus: true,
      },
    };
    this.backcanvas = React.createRef();
  }

  componentDidMount() {
    const img = new Image();
    img.src = this.props.product.back_image;
    let left , top, width, height;
    if (this.props.product.workArea.back) {
      left = this.props.product.workArea.back.left;
      top = this.props.product.workArea.back.top;
      width = this.props.product.workArea.back.width;
      height  = this.props.product.workArea.back.height;
    } else {
      left , top = 0 ,
      width, height = '100%';
    }

    this.backcanvas.current = new fabric.Canvas(this.backcanvas.current, {
      preserveObjectStacking: true,
      height: 652,
      width: 670,
    });
    this.backcanvas.current.setBackgroundImage(this.props.product.back_image, () => this.backcanvas.current.renderAll());
    this.backcanvas.current.on('object:removed', () => {
      this.props.countObjectBS(this.backcanvas.current.getObjects().length -1);
    });
    this.backcanvas.current.on('object:added', () => {
      this.props.countObjectBS(this.backcanvas.current.getObjects().length -1);
      this.updateHistory();
      this.updateTextOptions();
    });
    this.backcanvas.current.on('mouse:down', options => {
      if (options.target && options.target.text) {
        this.props.imageOrtext('Text');
        this.updateTextOptions();
      }
    });

    this.backcanvas.current.on('object:modified', () => {
      this.updateHistory();
      this.updateTextOptions();
    });
    var out_frame = new fabric.Rect({
      width: width,
      height: height,
      top: top,
      left: left,
      fill: 'rgba(0, 0, 0, 0)',
      selectable: false,
      stroke: 'rgba(0,0,0,1)',
      strokeWidth: 1,
      evented: false,
      id: 'out_frame'
    });
    this.backcanvas.current.add(out_frame);
    this.props.undoBackSide(this.undo);
    this.props.redoBackSide(this.redo);
    this.props.selectCurrent(['BACK_CANVAS', this.backcanvas.current]);
  }

  updateTextOptions = () => {
    if (this.backcanvas.current.getActiveObject()) {
      var obj = this.backcanvas.current.getActiveObject();
      const style = {
        fontFamily: obj.fontFamily,
        fontSize: obj.fontSize,
        lineHeight: obj.lineHeight,
        textAlign: obj.textAlign,
        textDecoration: obj.underline,
        charSpacing: obj.charSpacing
      };
      this.props.setStyle(style);
    }
  }

  updateHistory = () => {
    const { canvasHistory } = this.state;
    if (this.state.canvasHistory.undoStatus === true || this.state.canvasHistory.redoStatus === true) {
      console.log('Do not do anything, this got triggered automatically while the undo and redo actions were performed');
    } else {
      const jsonData = this.backcanvas.current.toJSON();
      const canvasAsJson = JSON.stringify(jsonData);

      // NOTE: This is to replace the canvasHistory when it gets rewritten 20180912:Alevale
      if (canvasHistory.currentStateIndex < canvasHistory.state.length - 1) {

        const indexToBeInserted = canvasHistory.currentStateIndex + 1;
        canvasHistory.state[indexToBeInserted] = canvasAsJson;
        const elementsToKeep = indexToBeInserted + 1;
        console.log(`History rewritten, preserved ${elementsToKeep} items`);
        canvasHistory.state = canvasHistory.state.splice(0, elementsToKeep);

        // NOTE: This happens when there is a new item pushed to the canvasHistory (normal case) 20180912:Alevale
      } else {
        //  console.log('push to canvasHistory');
        canvasHistory.state.push(canvasAsJson);
      }

      canvasHistory.currentStateIndex = canvasHistory.state.length - 1;
    }
  };

undo = () => {
  const { canvasHistory } = this.state;
  if (canvasHistory.currentStateIndex - 1 === -1) {
    console.log('do not do anything anymore, you are going far to the past, before creation, there was nothing');
    return;
  }

  if (canvasHistory.undoFinishedStatus) {
    canvasHistory.undoFinishedStatus = false;
    canvasHistory.undoStatus = true;
    this.backcanvas.current.loadFromJSON(canvasHistory.state[canvasHistory.currentStateIndex - 1], () => {
      this.backcanvas.current.renderAll();
      canvasHistory.undoStatus = false;
      canvasHistory.currentStateIndex--;
      canvasHistory.undoFinishedStatus = true;
    });
  }
};

redo = () => {
  const { canvasHistory } = this.state;

  if (canvasHistory.currentStateIndex + 1 === canvasHistory.state.length) {
    console.log('do not do anything anymore, you do not know what is after the present, do not mess with the future');
    return;
  }
  if (canvasHistory.redoFinishedStatus) {
    canvasHistory.redoFinishedStatus = false;
    canvasHistory.redoStatus = true;
    this.backcanvas.current.loadFromJSON(canvasHistory.state[canvasHistory.currentStateIndex + 1], () => {
      this.backcanvas.current.renderAll();
      canvasHistory.redoStatus = false;
      canvasHistory.currentStateIndex++;
      canvasHistory.redoFinishedStatus = true;
    });
  }
};

addEng = () => {
  let left , top, width, height;
  if (this.props.product.workArea) {
    left = this.props.product.workArea.back.left;
    top = this.props.product.workArea.back.top;
    width = this.props.product.workArea.back.width;
    height  = this.props.product.workArea.back.height;
  } else {
    // eslint-disable-next-line no-sequences
    left , top = 0;
    width = 670; // defaulut image size from welovelaser
    height = 652; // /////////////////////////////////
  }
  var out_frame = new fabric.Rect({
    width: width,
    height: height,
    top: top,
    left: left,
    absolutePositioned: true
  });
  const { engColor } = this.props.product;
  const { backEngraving } = this.props;
  const last = backEngraving[backEngraving.length -1];
  if (typeof(last) === 'string') {
    var text = new fabric.IText(last, {
      top: top,
      left: left,
      objecttype: 'text',
      fontFamily: 'Abel',
      lineHeight: 1.0,
      charSpacing: 50,
      fill: `rgb(${engColor.r},${ engColor.g}, ${engColor.b})`,
      borderColor: '#ff1212',
      cornerColor: '#ff5454',
      clipPath: out_frame
    });
    this.backcanvas.current.add(text);
    text.setCoords();
    this.backcanvas.current.setActiveObject(text);
    this.backcanvas.current.renderAll(text);
    this.props.setStyle({
      fontSize: 40,
      lineHeight: 1,
      charSpacing: 50,
      textDecoration: false,
      textAlign: '',
      fontFamily: 'Abel',
    });
  } else if(last.url.includes('<svg')) {
    if (last.url.includes('<image') || last.url.includes('<text') ) {
      var message = 'Die hochgeladene Datei ist für eine Gravur leider nicht geeignet. SVG`s dürfen nur 100% schwarze und 100% weißen Flächen/Pfade beinhalten (monochrome Faxversion). Schriftarten müssen zwingend in Pfade umgewandelt sein. SVG´s mit eingebetteten Pixelgrafiken können technisch nicht verarbeitet werden.';
      alert(message);
    } else {
      fabric.loadSVGFromString(last.url, objects => {
        var obj = [];
        for(var i = 0; i < objects.length; i++) {
          obj.push(SVGFilter( objects[i], engColor));
        }
        var group =  fabric.util.groupSVGElements(objects, last.url);
        group.scale(Math.max(Math.min(width / 670, height / 652, 0.9)), 0.1);
        group.clipPath = out_frame;
        group.id = 'svg';
        group.left = left;
        group.top = top;
        group.borderColor = '#ff1212';
        group.cornerColor = '#ff5454';
        group.toObject = (function(toObject) {
          return function() {
            return fabric.util.object.extend(toObject.call(this), {
              id: this.id
            });
          };
        })(group.toObject);
        this.backcanvas.current.add(group);
        group.setCoords();
        this.backcanvas.current.renderAll();
      });
    }
  } else {
    new fabric.Image.fromURL(last.url, myImg => {
      var img =  myImg.set({
        top: top,
        left: left,
        id: last.id,
        clipPath: out_frame,
        borderColor: '#ff1212',
        cornerColor: '#ff5454', }).scale(0.1);
      img.toObject = (function(toObject) {
        return function() {
          return fabric.util.object.extend(toObject.call(this), {
            id: this.id
          });
        };
      })(img.toObject);
      this.backcanvas.current.add(img);
      img.setCoords();
      this.backcanvas.current.setActiveObject(img);
      this.backcanvas.current.renderAll(img);
    });
  }
}

componentDidUpdate(prevProps) {
  if (prevProps.backEngraving !== this.props.backEngraving) {
    this.addEng();
  }
}


render() {
  return(<div id='backsideContainer'   style={{
    display: this.props.sideIs === 'front'?  'none' : 'block'
  }}>
    <canvas id='Bcanvas' ref={this.backcanvas}
      // style={{ maxWidth: '100%' }}
    ></canvas>
  </div>);
}
}

const mapStateToProps = state => {
  const { product } = state.productReducer;
  const { backEngraving } = state.backsideReducer;
  const { sideIs } = state.optionsReducer;
  const { canvasBorder } = state.optionsReducer;
  return {
    backEngraving, sideIs, canvasBorder, product
  };
};

const mapDispatchToProps = dispatch => ({
  undoBackSide: undoFun => dispatch(undoBackSide(undoFun)),
  redoBackSide: undoFun => dispatch(redoBackSide(undoFun)),
  setStyle: fs => dispatch(setStyle(fs)),
  selectCurrent: current => dispatch(selectCurrent(current)),
  imageOrtext: data => dispatch(imageOrtext(data)),
  countObjectBS: data => dispatch(countObjectBS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackSide);
