import { SET_CONTENT,
  FRONT_CANVAS, BACK_CANVAS,
  SET_STYLE,
  SET_SIDE,
  FRONT_ADD_FIGURE,
  BACK_ADD_FIGURE,
  UNDO_FRONT_CANVAS, UNDO_BACK_CANVAS, REDO_BACK_CANVAS, REDO_FRONT_CANVAS,
  HIDE_CANVAS_BORDER, ORGINAL_PICS, ACCEPT_TERMS, objectFS, objectBS,

  UNSELECT_GALLERY_CATEGORY, SET_GALLERY_SEARCH,
  LOAD_GALLERY_CATEGORIES, LOAD_GALLERY_CATEGORY_IMAGES,
} from './actions';

const initCurrent = {
  frontCurrent: null,
  backCurrent: null
};

const initFrontside = {
  frontEngraving: [],
  countFS: 0,
};

const initBackside = {
  backEngraving: [],
  countBS: 0
};

const initUndoRedo = {
  undoFront: [],
  undoBack: [],
  redoFront: [],
  redoBack: []
};

const initOptions = {
  contentIs: 'Text',
  sideIs: 'front',
  // canvasBorder:  {},
  orginalPics: [],
  userAccept: false,
  gallery: {
    selected: 0,
    images: [],
    categories: [],
    search: ''
  }
};

const initStyle = {
  style: {
    fontSize: 40,
    lineHeight: 1.0,
    charSpacing: 50,
    textDecoration: false,
    textAlign: '',
    fontFamily: 'Abel',
  }
};

export const optionsReducer = (state = initOptions, action) => {
  switch (action.type) {
  case SET_CONTENT:
    return {
      ...state,
      contentIs: action.payload
    };
  case SET_SIDE: {
    return {
      ...state,
      sideIs: action.payload
    };
  }

  case HIDE_CANVAS_BORDER: {
    let canvasBorder = [state.canvasBorder];
    canvasBorder = action.payload;
    return {
      ...state,
      canvasBorder
    };
  }

  case ACCEPT_TERMS: {
    let userAccept = [state.acceptTerms];
    userAccept = action.payload;
    return {
      ...state,
      userAccept
    };
  }

  case ORGINAL_PICS: {
    const orginalPics = action.payload;
    return {
      ...state,
      orginalPics
    };
  }

  case UNSELECT_GALLERY_CATEGORY: {
    return {
      ...state,
      gallery: {
        ...state.gallery,
        selected: 0,
        images: []
      }
    }
  }

  case LOAD_GALLERY_CATEGORIES: {
    return {
      ...state,
      gallery: {
        ...state.gallery,
        categories: action.payload
      }
    };
  }

  case LOAD_GALLERY_CATEGORY_IMAGES: {
    return {
      ...state,
      gallery: {
        ...state.gallery,
        images: action.payload
      }
    };
  }

  case SET_GALLERY_SEARCH: {
    return {
      ...state,
      gallery: {
        ...state.gallery,
        search: action.payload
      }
    }
  }

  default:
    return state;
  }
};


export const currentFigureReducer = (state = initCurrent, action) => {

  switch (action.type) {
  case FRONT_CANVAS:
    let frontCurrent = [state.frontCurrent];
    frontCurrent = action.payload;
    return {
      ...state,
      frontCurrent
    };
  case BACK_CANVAS:
    let backCurrent = [state.backCurrent];
    backCurrent = action.payload;
    return {
      ...state,
      backCurrent
    };
  default:
    return state;
  }
};

export const styleReducer = (state = initStyle, action) => {
  switch (action.type) {
  case SET_STYLE:
    let style  = [state.style];
    style = action.payload;
    return {
      style
    };
  default:
    return state;
  }
};

export const productReducer = (state = {}, action) => {
  switch (action.type) {
  case 'ADD_PRODUCT':
    const product = action.payload;
    return {
      product
    };
  default:
    return state;
  }
};

export const frontsideReducer = (state = initFrontside, action) => {
  switch (action.type) {
  case FRONT_ADD_FIGURE:
    const frontEngraving  = [ ...state.frontEngraving];
    frontEngraving.push(action.payload);
    return {
      ...state,
      frontEngraving
    };
  case objectFS:
    const countFS  = action.payload;
    return {
      ...state,
      countFS
    };
  default:
    return state;
  }
};

export const backsideReducer = (state = initBackside, action) => {
  switch (action.type) {
  case BACK_ADD_FIGURE:
    const backEngraving  = [ ...state.backEngraving];
    backEngraving.push(action.payload);
    return {
      ...state,
      backEngraving
    };
  case objectBS:
    const countBS  = action.payload;
    return {
      ...state,
      countBS
    };
  default:
    return state;
  }
};

export const undoredoReducer = (state = initUndoRedo, action) => {
  switch (action.type) {
  case UNDO_FRONT_CANVAS:
    let undoFront = [state.undoFront];
    undoFront = action.payload;
    return {
      ...state,
      undoFront
    };
  case UNDO_BACK_CANVAS:
    let undoBack = [state.undoBack];
    undoBack = action.payload;
    return {
      ...state,
      undoBack
    };

  case REDO_FRONT_CANVAS:
    let redoFront = [state.redoFront];
    redoFront = action.payload;
    return {
      ...state,
      redoFront
    };
  case REDO_BACK_CANVAS:
    let redoBack = [state.redoBack];
    redoBack = action.payload;
    return {
      ...state,
      redoBack
    };
  default:
    return state;
  }
};
