
export const SVGFilter = (object, engColor) => {
  console.log(object, 'herer is obj');
  const R  = engColor.r;
  const G =  engColor.g;
  const B = engColor.b;
  function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let r, g,b;
    if(result) {
      r =  parseInt(result[1], 16);
      g = parseInt(result[2], 16);
      b =  parseInt(result[3], 16);
    }
    return `rgb(${r}, ${g}, ${b})`;
  }
  // delete gropu & path to make the <path> in svg empty
  // object.path = [];
  // object.group = {};

  // check if color is hex change it to rgb
  // we need rgb to compare if r or g or b bigger than 155 which is gray to white shold be deleted in the else
  // if smaller which is gray to black fill it with engraving color

  function rgbExtract() {
    let color = object.fill;
    if (typeof color == 'string' && color.includes('#')) {
      color = hexToRgb(color);
    }
    var matchColors = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
    var match = matchColors.exec(color);
    if (match) {
      var a = parseInt(match[1], 10);
      var b = parseInt(match[2], 10);
      var c = parseInt(match[3], 10);
      if ( ( a < 150 ) || ( b < 150 )|| (c < 150) ) {
        object.fill = `rgb(${R},${G},${B})`;
        object.opacity = 1;
        object.stroke = '';
      } else {
        object.opacity = 0;
      }
      object.stroke = '';
      return object;
    } else {
      object.fill = '';
      object.stroke = `rgb(${R},${G},${B})`;
    }
  }


  return rgbExtract();
};