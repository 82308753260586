import React, { Component } from 'react';
import '../style/content';
import { connect } from 'react-redux';
import TextOptions from './TextOptions';
import ImageOptions from './ImageOptions';
import GalleryOptions from './GalleryOptions';


class Content extends Component {

  render() {
    return(
      <div className='content-wrapper'>
        { (() => {
          switch(this.props.contentIs) {
            case 'Text': return <TextOptions />;
            case 'uploadImage': return <ImageOptions />;
            case 'galleryImages': return <GalleryOptions />;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { contentIs } = state.optionsReducer;
  return {
    contentIs
  };
};



export default connect(mapStateToProps)(Content);
