/* eslint-disable prefer-destructuring */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEngraving,
         fetchGalleryCategories, fetchGalleryImages,
         unselectGalleryCategory, setGallerySearch } from '../actions';
import { store } from '../store';

class GalleryOptions extends Component {

  constructor(props) {
    super(props);

    store.dispatch(fetchGalleryCategories);
  }

  addSVGToProduct = img => {

    fetch(img.url, { headers: {
        'pragma': 'no-cache',
        'cache-control': 'no-cache'
      }})
      .then(r => r.blob())
      .then(b => {
        const reader = new FileReader();
        reader.addEventListener('load', evt => {
          const side = this.props.sideIs === 'front' ? 'FRONT_ADD_FIGURE' : 'BACK_ADD_FIGURE';

          this.props.addEngraving([side, { url: evt.target.result }]);
        });
        reader.readAsText(b);
      });
  }

  showPreview = img => {
    let previewContainer = document.getElementById('gallery-preview');
    previewContainer.style.zIndex = '3';
    previewContainer.querySelector('img').src = img.url;
    previewContainer.querySelector('span').innerHTML = img.name;

    previewContainer.classList.add('show');
  }

  hidePreview = () => {
    document.getElementById('gallery-preview').classList.remove('show');
  }

  renderImages = () => {
    let images = this.props.images.filter(img => {
      if (this.props.search === '') return true;
      let keywords = this.props.search.split(/, /);
      return keywords.every(tested => img.keywords.includes(tested.trim().toLowerCase()));
    });

    if (!images.length) {
      return <div className="gallery-empty">Nothing found</div>;
    }

    return images.map((img) => {
        return <img key={img.id}
        src={img.url}
        alt={img.name}
        className="gallery-image"
        onClick={() => this.addSVGToProduct(img)}
        onMouseEnter={() => this.showPreview(img)}
        onMouseLeave={() => this.hidePreview()}
          />
      })
  }

  render() {
    const shouldRenderImages = !!this.props.images.length;
    const shouldRenderCategories = !shouldRenderImages && !!this.props.categories.length;

    return (
      <div className="gallery-wrapper">

        <div id="gallery-preview">
          <img src="" alt="" />
          <span></span>
        </div>

        <div className= 'gallery-container'>
          <div className='hide-content-button' onClick={ () =>
              document.getElementById('content').style.zIndex = '1' }>X</div>

          { shouldRenderImages &&
              <div className="InputAddOn searchbox sticky">
                <span className="InputAddOn-item"><i className="icon-search"></i></span>
                <input className="InputAddOn-field"
                      onChange={(e) => {
                        this.hidePreview();
                        this.props.setGallerySearch(e.target.value);
                      }}
                />
                <button className="InputAddOn-item back-button"
                        onClick={() => {
                          store.dispatch(this.props.unselectGalleryCategory);
                          this.props.setGallerySearch('');
                        }}
                ><i className="icon-left"></i></button>
              </div>
          }

          <div className='gallery-options'>
            { shouldRenderCategories && this.props.categories.map((category) => {
                return <a className="gallery-category"
                  onClick={() => store.dispatch(fetchGalleryImages(category.id))}
                  key={category.id}
                >
                  {category.name}
                </a>
              })
            }

            { shouldRenderImages &&
              this.renderImages()
            }

            {
              !shouldRenderCategories && !shouldRenderImages &&
                <div>Category is empty</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { sideIs, gallery: {selected, images, categories, search}} = state.optionsReducer;

  return {
    sideIs, selected, images, categories, search
  }
};

const mapDispatchToProps = dispatch => ({
  unselectGalleryCategory: () => dispatch(unselectGalleryCategory()),
  addEngraving: data => dispatch(addEngraving(data)),
  setGallerySearch: (query) => dispatch(setGallerySearch(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GalleryOptions);
