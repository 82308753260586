import React from 'react';
import WeLoveLaser_Logo from './Logos/WeLoveLaser_Logo';
import { ReactSVG } from 'react-svg';

var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident/.test(ua);
if ( isIE ) {
  alert('Dieser Browser wird nicht unterstützt, bitte benutzen Sie Chrome, Firefox, Edge, Opera oder Safari');
  window.location.href = 'https://www.welovelaser.de/';
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div>
        <h4 style={{ display: 'inline' }}>
        Leider ist ein Fehler aufgetreten. Bitte versuchen Sie die Seite in einem anderen Browser zu laden
        </h4>
        <ReactSVG src={WeLoveLaser_Logo} />

      </div>;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;