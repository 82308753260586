import React, { Component } from 'react';
import Tools  from './ChildComponents/Tools';
import Content from './ChildComponents/Content';
import Engraving from './ChildComponents/Engraving';
import ErrorBoundary from './ErrorBoundary';
import PopUp from './ChildComponents/PopUp';
import './style/fonts.css';

class App extends Component {
  constructor(props) {
    super(props);
    const { innerWidth: width, innerHeight: height } = window;
    this.state = {
      showPopup: innerWidth < 500 ? true : false,
      innerWidth: innerWidth,
      innerHeight: innerHeight
    };
  }

   toggleOffPopup() {
    this.setState({
      showPopup: false
    });
  }

  render() {
    return (
      <div>
        <div className='Container'>
          <ErrorBoundary>
            <div className='ContainerChild tools'>
              <Tools  />
            </div>
            <div  id='content' className='ContainerChild content-options'>
              <Content />
            </div>
            <div className='ContainerChild engraving'>
              <Engraving  />
              {this.state.showPopup ?
                <PopUp
                  closePopup={this.toggleOffPopup.bind(this)}
                />
                : null
              }
            </div>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default App;
