import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { optionsReducer,
  currentFigureReducer,
  styleReducer,
  productReducer,
  frontsideReducer,
  backsideReducer,
  undoredoReducer
} from './reducers';



function lastAction(state = null, action) {
  return action;
}


const rootReducer = combineReducers({
  optionsReducer, productReducer, frontsideReducer,
  backsideReducer, currentFigureReducer, styleReducer, undoredoReducer,
  lastAction
});

export const store =  createStore(rootReducer, applyMiddleware(thunkMiddleware));

// store.subscribe( () => {
//   console.log(store.getState().lastAction, 'last action');
//   console.log(store.getState());
// });
