/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
import React, { Component }from 'react';
import { connect } from 'react-redux';
import { undoFrontSide, selectCurrent, setStyle,
  redoFrontSide, imageOrtext, countObjectFS } from '../actions';
import { fabric } from 'fabric';
import { SVGFilter } from './SVGFilter';

class FrontSide extends Component {
  constructor(props) {
    super(props);
    this.outFrameIndex = 0;
    this.state = {
      canvasHistory: {
        state: [],
        currentStateIndex: -1,
        undoStatus: false,
        redoStatus: false,
        undoFinishedStatus: true,
        redoFinishedStatus: true,
      },
    };
    this.frontcanvas = React.createRef();
  }

  componentDidMount() {
    const img = new Image();
    img.src = this.props.frontImage;
    let left , top, width, height;
    if (this.props.product.workArea) {
      left = this.props.product.workArea.front.left;
      top = this.props.product.workArea.front.top;
      width = this.props.product.workArea.front.width;
      height  = this.props.product.workArea.front.height;
    } else {
      // eslint-disable-next-line no-sequences
      left , top = 0;
      width = 670; // defaulut image size from welovelaser
      height = 652; // /////////////////////////////////
    }
    this.frontcanvas.current = new fabric.Canvas(this.frontcanvas.current, {
      preserveObjectStacking: true,
      height: 652,
      width: 670,
      // selection : false,
      // controlsAboveOverlay:true,
      // centeredScaling:true,
      // allowTouchScrolling: true
    });
    this.frontcanvas.current.setBackgroundImage(this.props.frontImage, () => this.frontcanvas.current.renderAll());
    this.frontcanvas.current.on('mouse:down', options => {
      if (options.target && options.target.text) {
        this.props.imageOrtext('Text');
        this.updateTextOptions();
      }
    });
    this.frontcanvas.current.on('object:modified', () => {
      this.updateHistory();
      this.updateTextOptions();
    });
    var out_frame = new fabric.Rect({
      width: width,
      height: height,
      top: top,
      left: left,
      fill: 'rgba(0, 0, 0, 0)',
      selectable: false,
      stroke: 'rgba(0,0,0,1)',
      strokeWidth: 1,
      evented: false,
      id: 'out_frame'
    });
    this.frontcanvas.current.add(out_frame);
    this.outFrameIndex = this.frontcanvas.current.getObjects().length - 1;

    this.frontcanvas.current.on('object:removed', () => {
      this.props.countObjectFS(this.frontcanvas.current.getObjects().length -1);
    });

    this.frontcanvas.current.on('object:added', () => {
      // -1 = the out frame
      this.props.countObjectFS(this.frontcanvas.current.getObjects().length -1);
      this.updateHistory();
      this.updateTextOptions();
    });
    this.props.undoFrontSide(this.undo);
    this.props.redoFrontSide(this.redo);
    this.props.selectCurrent(['FRONT_CANVAS', this.frontcanvas.current]);
  }
    updateTextOptions = () => {
      if (this.frontcanvas.current.getActiveObject()) {
        var obj = this.frontcanvas.current.getActiveObject();
        const style = {
          fontFamily: obj.fontFamily,
          fontSize: obj.fontSize /1,
          lineHeight: obj.lineHeight /1,
          textAlign: obj.textAlign,
          textDecoration: obj.underline,
          charSpacing: obj.charSpacing
        };
        this.props.setStyle(style);
      }
    }

    updateHistory = () => {
      // now there is fabric history package in npm
      const { canvasHistory } = this.state;
      if (this.state.canvasHistory.undoStatus === true || this.state.canvasHistory.redoStatus === true) {
        console.log('Do not do anything, this got triggered automatically while the undo and redo actions were performed');
      } else {
        const jsonData = this.frontcanvas.current.toJSON();
        const canvasAsJson = JSON.stringify(jsonData);

        // NOTE: This is to replace the canvasHistory when it gets rewritten 20180912:Alevale
        if (canvasHistory.currentStateIndex < canvasHistory.state.length - 1) {

          const indexToBeInserted = canvasHistory.currentStateIndex + 1;
          canvasHistory.state[indexToBeInserted] = canvasAsJson;
          const elementsToKeep = indexToBeInserted + 1;
          console.log(`History rewritten, preserved ${elementsToKeep} items`);
          canvasHistory.state = canvasHistory.state.splice(0, elementsToKeep);

          // NOTE: This happens when there is a new item pushed to the canvasHistory (normal case) 20180912:Alevale
        } else {
          //  console.log('push to canvasHistory');
          canvasHistory.state.push(canvasAsJson);
        }

        canvasHistory.currentStateIndex = canvasHistory.state.length - 1;
      }
    };

undo = () => {
  const { canvasHistory } = this.state;
  if (canvasHistory.currentStateIndex - 1 === -1) {
    console.log('do not do anything anymore, you are going far to the past, before creation, there was nothing');
    return;
  }

  if (canvasHistory.undoFinishedStatus) {
    canvasHistory.undoFinishedStatus = false;
    canvasHistory.undoStatus = true;

    this.frontcanvas.current.loadFromJSON(canvasHistory.state[canvasHistory.currentStateIndex - 1], () => {
      // prevent working area from being moved
      this.frontcanvas.current.item(this.outFrameIndex).selectable = false;
      this.frontcanvas.current.renderAll();
      canvasHistory.undoStatus = false;
      canvasHistory.currentStateIndex--;
      canvasHistory.undoFinishedStatus = true;
    });
  }
};

 redo = () => {
   const { canvasHistory } = this.state;
   if (canvasHistory.currentStateIndex + 1 === canvasHistory.state.length) {
     console.log('do not do anything anymore, you do not know what is after the present, do not mess with the future');
     return;
   }
   if (canvasHistory.redoFinishedStatus) {
     canvasHistory.redoFinishedStatus = false;
     canvasHistory.redoStatus = true;
     this.frontcanvas.current.loadFromJSON(canvasHistory.state[canvasHistory.currentStateIndex + 1], () => {
       // prevent working area from being moved
       this.frontcanvas.current.item(this.outFrameIndex).selectable = false;
       this.frontcanvas.current.renderAll();
       canvasHistory.redoStatus = false;
       canvasHistory.currentStateIndex++;
       canvasHistory.redoFinishedStatus = true;
     });
   }
 };

  addEng = () => {
    let left , top, width, height;
    if (this.props.product.workArea) {
      left = this.props.product.workArea.front.left;
      top = this.props.product.workArea.front.top;
      width = this.props.product.workArea.front.width;
      height  = this.props.product.workArea.front.height;
    } else {
      // eslint-disable-next-line no-sequences
      left , top = 0;
      width = 670; // defaulut image size from welovelaser
      height = 652; // /////////////////////////////////
    }
    var out_frame = new fabric.Rect({
      width: width,
      height: height,
      top: top,
      left: left,
      absolutePositioned: true
    });
    // this.frontcanvas.current.add(out_frame);
    const { engColor } = this.props.product;
    const { frontEngraving } = this.props;
    // To-DO => check if no left and top
    const last = frontEngraving[frontEngraving.length -1];
    if (typeof(last) === 'string') {
      var text = new fabric.IText(last, {
        top: top,
        left: left,
        objecttype: 'text',
        fontFamily: 'Abel',
        lineHeight: 1.0,
        charSpacing: 50,
        borderColor: '#ff1212',
        cornerColor: '#ff5454',
        fill: `rgb(${engColor.r},${ engColor.g}, ${engColor.b})`,
        clipPath: out_frame
      });

      this.frontcanvas.current.add(text);
      text.setCoords();
      this.frontcanvas.current.setActiveObject(text);
      this.frontcanvas.current.renderAll(text);
      this.props.setStyle({
        fontSize: 40,
        lineHeight: 1,
        charSpacing: 50,
        textDecoration: false,
        textAlign: '',
        fontFamily: 'Abel',
      });
    } else if(last.url.includes('<svg')) {
      if (last.url.includes('<image') || last.url.includes('<text') ) {
        var message = 'Die hochgeladene Datei ist für eine Gravur leider nicht geeignet. SVG`s dürfen nur 100% schwarze und 100% weißen Flächen/Pfade beinhalten (monochrome Faxversion). Schriftarten müssen zwingend in Pfade umgewandelt sein. SVG´s mit eingebetteten Pixelgrafiken können technisch nicht verarbeitet werden.';
        alert(message);
      } else {
        fabric.loadSVGFromString(last.url, objects => {
          var obj = [];
          for(var i = 0; i < objects.length; i++) {
            obj.push(SVGFilter( objects[i], engColor));
          }
          var group =  fabric.util.groupSVGElements(objects, last.url);
          group.scale(Math.max(Math.min(width / 670, height / 652, 0.9)), 0.1);
          group.clipPath = out_frame;
          group.id = 'svg';
          group.left = left;
          group.top = top;
          group.borderColor = '#ff1212';
          group.cornerColor = '#ff5454';
          group.toObject = (function(toObject) {
            return function() {
              return fabric.util.object.extend(toObject.call(this), {
                id: this.id,
              });
            };
          })(group.toObject);
          this.frontcanvas.current.add(group);
          group.setCoords();
          this.frontcanvas.current.renderAll();
        });
      }
    } else {
      new fabric.Image.fromURL(last.url, myImg => {
        var img =  myImg.set({
          top: top,
          left: left,
          id: last.id,
          clipPath: out_frame,
          borderColor: '#ff1212',
          cornerColor: '#ff5454', }).scale(0.1);
        img.toObject = (function(toObject) {
          return function() {
            return fabric.util.object.extend(toObject.call(this), {
              id: this.id
            });
          };
        })(img.toObject);
        this.frontcanvas.current.add(img);
        img.setCoords();
        this.frontcanvas.current.setActiveObject(img);
        this.frontcanvas.current.renderAll(img);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.frontEngraving !== this.props.frontEngraving) {
      this.addEng();
    }
  }


  render() {
    return(<div  id='frontsideContainer' style={{
      display: this.props.sideIs === 'front'?  'block' : 'none'
    }}>
      <canvas id= 'Fcanvas' ref={this.frontcanvas}
      ></canvas>
    </div>);
  }
}

const mapStateToProps = state => {
  const { frontEngraving } = state.frontsideReducer;
  const frontImage = state.productReducer.product.front_image;
  const { sideIs } = state.optionsReducer;
  const { canvasBorder } = state.optionsReducer;
  const { product } = state.productReducer;
  const { frontCurrent } = state.currentFigureReducer;

  return {
    frontEngraving, frontImage, sideIs, canvasBorder, product, frontCurrent
  };
};

const mapDispatchToProps = dispatch => ({
  undoFrontSide: undoFun => dispatch(undoFrontSide(undoFun)),
  redoFrontSide: undoFun => dispatch(redoFrontSide(undoFun)),
  selectCurrent: current => dispatch(selectCurrent(current)),
  setStyle: fs => dispatch(setStyle(fs)),
  imageOrtext: data => dispatch(imageOrtext(data)),
  countObjectFS: data => dispatch(countObjectFS(data)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(FrontSide));
