import LobsterTwo from '../fonts/LobsterTwo.ttf';
import Bitteritalic from '../fonts/Bitteritalic.ttf';
import OleoScript from '../fonts/OleoScript.ttf';
import boldLobsterTwoitalic from '../fonts/boldLobsterTwoitalic.ttf';
import Kameron from '../fonts/Kameron.ttf';
import Niconne from '../fonts/Niconne.ttf';
import VastShadow from '../fonts/VastShadow.ttf';
import RobotoSlab from '../fonts/RobotoSlab.ttf';
import boldPlayfairDisplayitalic from '../fonts/boldPlayfairDisplayitalic.ttf';
import LobsterTwoitalic from '../fonts/LobsterTwoitalic.ttf';
import Play from '../fonts/Play.ttf';
import Graduate from '../fonts/Graduate.ttf';
import boldCinzel from '../fonts/boldCinzel.ttf';
import boldPlayfairDisplay from '../fonts/boldPlayfairDisplay.ttf';
import boldRaleway from '../fonts/boldRaleway.ttf';
import boldBitter from '../fonts/boldBitter.ttf';
import boldPTSans from '../fonts/boldPTSans.ttf';
import boldPlay from '../fonts/boldPlay.ttf';
import boldPTSansitalic from '../fonts/boldPTSansitalic.ttf';
import Ralewayitalic from '../fonts/Ralewayitalic.ttf';
import PTSansitalic from '../fonts/PTSansitalic.ttf';
import Teko from '../fonts/Teko.ttf';
import boldLobsterTwo from '../fonts/boldLobsterTwo.ttf';
import boldKameron from '../fonts/boldKameron.ttf';
import boldOleoScript from '../fonts/boldOleoScript.ttf';
import GreatVibes from '../fonts/GreatVibes.ttf';
import PTSans from '../fonts/PTSans.ttf';
import boldRobotoSlab from '../fonts/boldRobotoSlab.ttf';
import Michroma from '../fonts/Michroma.ttf';
import Bitter from '../fonts/Bitter.ttf';
import Abel from '../fonts/Abel.ttf';
import Monda from '../fonts/Monda.ttf';
import Cinzel from '../fonts/Cinzel.ttf';
import boldMonda from '../fonts/boldMonda.ttf';
import boldTeko from '../fonts/boldTeko.ttf';
import boldRalewayitalic from '../fonts/boldRalewayitalic.ttf';
import PlayfairDisplayitalic from '../fonts/PlayfairDisplayitalic.ttf';
import Raleway from '../fonts/Raleway.ttf';
import CarterOne from '../fonts/CarterOne.ttf';
import PlayfairDisplay from '../fonts/PlayfairDisplay.ttf';
import Audiowide from '../fonts/Audiowide.ttf';
import lato from '../fonts/lato.ttf';

export const Fonts = {
  LobsterTwo,
  Bitteritalic,
  OleoScript,
  boldLobsterTwoitalic,
  Kameron,
  Niconne,
  VastShadow,
  RobotoSlab,
  boldPlayfairDisplayitalic,
  LobsterTwoitalic,
  Play,
  Graduate,
  boldCinzel,
  boldPlayfairDisplay,
  boldRaleway,
  boldBitter,
  boldPTSans,
  boldPlay,
  boldPTSansitalic,
  Ralewayitalic,
  PTSansitalic,
  Teko,
  boldLobsterTwo,
  boldKameron,
  boldOleoScript,
  GreatVibes,
  PTSans,
  boldRobotoSlab,
  Michroma,
  Bitter,
  Abel,
  Monda,
  Cinzel,
  boldMonda,
  boldTeko,
  boldRalewayitalic,
  PlayfairDisplayitalic,
  Raleway,
  CarterOne,
  PlayfairDisplay,
  Audiowide,
  lato,
};