import React from 'react';
import wwlLogo from '../Logos/wwl-logo.svg';
import iconImage from '../Logos/icon-image.svg';
import iconText from '../Logos/icon-text.svg';
import iconGallery from '../Logos/icon-gallery.svg';
import iconDuplicate from '../Logos/icon-tool-duplicate.svg';
import iconDelete from '../Logos/icon-tool-delete.svg';
import iconCenterHo from '../Logos/icon-tool-centerhorizontal.svg';
import iconCenterVe from '../Logos/icon-tool-centervertical.svg';
import iconRedo from '../Logos/icon-tool-redo.svg';
import iconUndo from '../Logos/icon-tool-undo.svg';
import iconToTop from '../Logos/icon-tool-totop.svg';
import iconToButtom from '../Logos/icon-tool-tobottom.svg';
import iconToRight from '../Logos/icon-tool-toright.svg';
import iconToLeft from '../Logos/icon-tool-toleft.svg';
import { ReactSVG } from 'react-svg';
import { imageOrtext } from '../actions';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import '../style/tools.css';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);



const Tools = props => {
  let canvas, workArea;
  if (props.sideIs === 'front') {
    canvas = props.frontCurrent;
    workArea = props.product.workArea.front;
  } else {
    canvas = props.backCurrent;
    workArea = props.product.workArea.back;
  }

  function undo() {
    if (props.sideIs === 'front') {
      props.undoFront();
    } else props.undoBack();
  }

  function redo() {
    if (props.sideIs === 'front') {
      props.redoFront();
    } else props.redoBack();
  }

  function showPortraitOverlay () {
    // for portrait orientation - show content using z-index
    if (document.documentElement.clientWidth <= document.documentElement.clientHeight ) {
      document.getElementById('content').style.zIndex = '3';
    }
  }

  return(
    <div className='tools-wrapper'>
      <ReactSVG src={wwlLogo} id='wwl-logo' />
      <div id='textImageIcon'>
        <div data-tip data-for="iconAddtext">
          <ReactSVG id='addText' className={props.contentIs === 'Text' ? 'textImagePic' : ''}
            src={iconText} onClick={ () => {
              showPortraitOverlay();
              props.imageOrtext('Text');
              canvas.discardActiveObject();
              canvas.renderAll();
            }}/>
          <ReactTooltip  disable={isMobile} id='iconAddtext'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Text einfügen</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconUpload">
          <ReactSVG src={iconImage} onClick={ () => {
            showPortraitOverlay();
            props.imageOrtext('uploadImage');
            canvas.discardActiveObject();
            canvas.renderAll();
          }}
          className={props.contentIs === 'uploadImage' ? 'textImagePic' : ''}
          />
          <ReactTooltip disable={isMobile} id='iconUpload'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Bild-Upload</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconGallery">
          <ReactSVG src={iconGallery} onClick={ () => {
            showPortraitOverlay();
            props.imageOrtext('galleryImages');
            canvas.discardActiveObject();
            canvas.renderAll();
          }}
          className={props.contentIs === 'galleryImages' ? 'textImagePic' : ''}
          />
          <ReactTooltip disable={isMobile} id='iconGallery'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Motivgalerie</span>
          </ReactTooltip>
        </div>
      </div>
      <div>
        <div  data-tip data-for="deleteEngraving">
          <ReactSVG src={iconDelete}  onClick={ () => {
            if (canvas.getActiveObject()) {
              canvas.remove(canvas.getActiveObject());
            } else alert('Wählen Sie eine Gravur aus');
          }} />
          <ReactTooltip disable={isMobile} id='deleteEngraving'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Löschen</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconDuoplicate">
          <ReactSVG src={iconDuplicate} onClick={ () => {
            if (canvas.getActiveObject()) {
              var object = canvas.getActiveObject();
              object.clone(clone => {
                clone.set({
                  borderColor: 'black',
                  cornerColor: '#3D3D3D',
                });
                canvas.add(clone);
                canvas.centerObject(clone);
                clone.setCoords();
                canvas.setActiveObject(clone);
                canvas.renderAll(clone);
              });
            } else alert('Wählen Sie eine Gravur aus');
          }} />
          <ReactTooltip disable={isMobile} id='iconDuoplicate'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Kopieren</span>
          </ReactTooltip>
        </div>
      </div>
      <div>
        <div data-tip data-for="iconAlignLeft">
          <ReactSVG src={iconToLeft} onClick={ () => {
            if (canvas.getActiveObject()) {
              const obj = canvas.getActiveObject();
              if (obj.angle && obj.angle < 90 ) {
                obj.set({ left: obj.angle + workArea.left });
                obj.setCoords();
                canvas.renderAll();
              } else  {
                obj.set({ left: workArea.left });
                obj.setCoords();
                canvas.renderAll();
              }
            } else  alert('Wählen Sie eine Gravur aus');
          } }/>
          <ReactTooltip disable={isMobile} id='iconAlignLeft'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Linksbündig ausrichten</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconAlignCenterH">
          <ReactSVG src={iconCenterVe} onClick={ () =>  {
            if (canvas.getActiveObject()) {
              const center = ((workArea.width - canvas.getActiveObject().getScaledWidth() ) /2) + workArea.left;
              const obj = canvas.getActiveObject();
              if (obj.angle && obj.angle < 90 ) {
                obj.set({ left: obj.angle + center });
                obj.setCoords();
                canvas.renderAll();
              } else {
                obj.set({ left: center });
                obj.setCoords();
                canvas.renderAll();
              }
            } else alert('Wählen Sie eine Gravur aus');
          }} />
          <ReactTooltip disable={isMobile} id='iconAlignCenterH'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Horizontal zentrieren</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconAlignRight">

          <ReactSVG src={iconToRight} onClick={ () =>  {
            if (canvas.getActiveObject()) {
              const right = workArea.width - canvas.getActiveObject().getScaledWidth() + workArea.left;
              const obj = canvas.getActiveObject();
              if (obj.angle && obj.angle < 90 ) {
                obj.set({ left: obj.angle + right });
                obj.setCoords();
                canvas.renderAll();
              } else {
                obj.set({ left: right });
                obj.setCoords();
                canvas.renderAll();
              }
            } else alert('Wählen Sie eine Gravur aus');
          }}/>
          <ReactTooltip disable={isMobile} id='iconAlignRight'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Rechtsbündig ausrichten</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconAlignTop">
          <ReactSVG src={iconToTop} onClick={ () =>   {
            if (canvas.getActiveObject()) {
              canvas.getActiveObject().set({ top: workArea.top });
              canvas.getActiveObject().setCoords();
              canvas.renderAll();
            } else alert('Wählen Sie eine Gravur aus');
          } } />
          <ReactTooltip disable={isMobile} id='iconAlignTop'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Oben ausrichten</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconAlignCenterV">
          <ReactSVG src={iconCenterHo} onClick={ () =>  {
            if (canvas.getActiveObject()) {
              const center = ((workArea.height - canvas.getActiveObject().getScaledHeight()) /2) + workArea.top ;
              canvas.getActiveObject().set({ top: center });
              canvas.getActiveObject().setCoords();
              canvas.renderAll();
            } else alert('Wählen Sie eine Gravur aus');
          }}
          />
          <ReactTooltip disable={isMobile} id='iconAlignCenterV'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Vertikal zentrieren</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconAlignBottom">
          <ReactSVG src={iconToButtom} onClick={ () =>  {
            if (canvas.getActiveObject()) {
              const bottom = workArea.height - canvas.getActiveObject().getScaledHeight() + workArea.top;
              canvas.getActiveObject().set({ top: bottom });
              canvas.getActiveObject().setCoords();
              canvas.renderAll();
            } else alert('Wählen Sie eine Gravur aus');
          }} />
          <ReactTooltip disable={isMobile} id='iconAlignBottom'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Unten ausrichten</span>
          </ReactTooltip>
        </div>
      </div>
      <div>
        <div data-tip data-for="iconUndo">
          <ReactSVG src={iconRedo} onClick={undo}/>
          <ReactTooltip disable={isMobile} id='iconUndo'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Zurück</span>
          </ReactTooltip>
        </div>
        <div data-tip data-for="iconRedo">
          <ReactSVG src={iconUndo} onClick={redo} />
          <ReactTooltip disable={isMobile} id='iconRedo'  place="right" className='tooltip-width'>
            <span className='tooltiptext'>Wiederholen</span>
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  const { frontCurrent, backCurrent } = state.currentFigureReducer;
  const { contentIs, sideIs } = state.optionsReducer;
  const { undoFront } = state.undoredoReducer;
  const { undoBack } = state.undoredoReducer;
  const { redoBack } = state.undoredoReducer;
  const { redoFront } = state.undoredoReducer;
  const { product } = state.productReducer;

  return {
    frontCurrent, contentIs, backCurrent, sideIs,
    undoFront, undoBack, redoBack, redoFront, product
  };
};

const mapDispatchToProps = dispatch => ({
  imageOrtext: data => dispatch(imageOrtext(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
