/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import textItalic from '../Logos/icon-text-italic.svg';
import textBold from '../Logos/icon-text-bold.svg';
import textUnderline from '../Logos/icon-text-underline.svg';
import textCenter from '../Logos/icon-text-center.svg';
import textLeft from '../Logos/icon-text-left.svg';
import textRight from '../Logos/icon-text-right.svg';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { addEngraving, setStyle } from '../actions';
var FontFaceObserver = require('fontfaceobserver');

const withoutBold = 'Abel, Audiowide,  CarterOne,  GreatVibes, Graduate, Great Vibes, Michroma, Niconne, vast-shadow, Teko, VastShadow';
const withoutItalic = 'Abel, Audiowide,  CarterOne, GreatVibes, Cinzel, Kameron,  Graduate, Great Vibes, Michroma, Niconne, vast-shadow, RobotoSlab, kameron, Monda, monda, OleoScript, Play, Teko, boldCinzel, boldKameron, boldMonda, boldOleoScript, boldPlay, boldRobotoSlab, VastShadow';

class TextOptions extends Component {
    state = {
      fontSize: 40,
      lineHeight: 0,
      charSpacing: 50,
      textDecoration: false,
      textAlign: '',
      fontFamily: '',
    }

    componentWillReceiveProps(nextprops) {
      const { style } = nextprops
      const { fontSize , lineHeight, charSpacing , textAlign, textDecoration, fontFamily } = style

      if(fontSize && fontSize !== this.state.fontSize) {
        this.setState({ fontSize : fontSize });
      }
      if(lineHeight && lineHeight !== this.state.lineHeight) {
        this.setState({ lineHeight : lineHeight });
      }
      if(charSpacing && parseInt(charSpacing) !== this.state.charSpacing) {
        this.setState({ charSpacing : parseInt(charSpacing)  });
      }
      if(textAlign && textAlign !== this.state.textAlign ) {
        this.setState({ textAlign : textAlign });
      }
      if(textDecoration && textDecoration !== this.state.textDecoration) {
        this.setState({ textDecoration : textDecoration });
      }
      if(fontFamily && fontFamily !== this.state.fontFamily) {
        this.setState({ fontFamily : fontFamily });
      }
      return true;
    }

    findFontfamilyvalue = () => {
      const { fontFamily } = this.state;
      if (fontFamily.includes('Abel')) {
        return 'Abel';
      } else if(fontFamily.includes('Audiowide')) {
        return 'Audiowide';
      } else if(fontFamily.includes('Bitter')) {
        return 'Bitter';
      } else if(fontFamily.includes('Carter')) {
        return 'CarterOne';
      } else if(fontFamily.includes('Cinzel')) {
        return 'Cinzel';
      } else if(fontFamily.includes('Graduate')) {
        return 'Graduate';
      } else if(fontFamily.includes('GreatVibes')) {
        return 'GreatVibes';
      } else if(fontFamily.includes('Kameron')) {
        return 'Kameron';
      } else if(fontFamily.includes('LobsterTwo')) {
        return 'LobsterTwo';
      } else if(fontFamily.includes('Michroma')) {
        return 'Michroma';
      } else if(fontFamily.includes('Monda')) {
        return 'Monda';
      } else if(fontFamily.includes('Niconne')) {
        return 'Niconne';
      } else if(fontFamily.includes('OleoScript')) {
        return 'OleoScript';
      } else if(fontFamily === 'Play') {
        return 'Play';
      } else if(fontFamily.includes('PlayfairDisplay')) {
        return 'PlayfairDisplay';
      } else if(fontFamily.includes('PTSans')) {
        return 'PTSans';
      } else if(fontFamily.includes('Raleway')) {
        return 'Raleway';
      } else if(fontFamily.includes('RobotoSlab')) {
        return 'RobotoSlab';
      } else if(fontFamily.includes('Teko')) {
        return 'Teko';
      } else if(fontFamily.includes('VastShadow')) {
        return 'VastShadow';
      }
    }

    render() {
      let canvas;
      if (this.props.sideIs === 'front') {
        canvas = this.props.frontCurrent;
      } else canvas = this.props.backCurrent;
      let  addFigure;
      if (this.props.sideIs === 'front') {
        addFigure = 'FRONT_ADD_FIGURE';
      } else addFigure = 'BACK_ADD_FIGURE';
      return(
        <div className='text-options'>
          <div className='hide-content-button' onClick={ () =>
            document.getElementById('content').style.zIndex = '1' }>X</div>
          <div className='rangeArea'>
            <div>
              <span>TEXTGRÖSSE</span>
              <input
                name='textSize'
                type="range"
                min="0" max="100"
                value={this.state.fontSize}
                onChange = { e => {
                  canvas.getActiveObject().set('fontSize', e.target.value);
                  canvas.renderAll();
                  const newStyle = { ...this.state, fontSize: e.target.value/1 };
                  this.props.setStyle(newStyle);
                }}
                step="1"/>
              <label htmlFor='textSize'>{this.state.fontSize}</label>
            </div>
            <div>
              <span>ZEILENHÖHE</span>
              <input
                type="range"
                min="0.5" max="5"
                value={this.state.lineHeight}
                onChange={ e => {
                  canvas.getActiveObject().set('lineHeight', `${ e.target.value}`);
                  canvas.renderAll();
                  const newStyle = { ...this.state, lineHeight: e.target.value/1 };
                  this.props.setStyle(newStyle);
                }
                }
                step="0.1"/>
              <label htmlFor='textSize'>{this.state.lineHeight}</label>

            </div>
            <div>
              <span>ZEICHENABSTAND</span>
              <input
                type="range"
                min="-500" max="1000"
                value={parseInt(this.state.charSpacing)}
                onChange={ e =>  {
                  canvas.getActiveObject().set('charSpacing',  e.target.value);
                  canvas.renderAll();
                  const newStyle = { ...this.state, charSpacing: e.target.value };
                  // this.setState({ charSpacing: e.target.value  });
                  this.props.setStyle(newStyle);
                }}
                step="10"/>
              <label htmlFor='textSize'>{parseInt(this.state.charSpacing) / 100}</label>

            </div>
          </div>
          <div className='font-area'>
            <div  className='alignment'>
              <span>AUSRICHTUNG</span>
              <div>

                <ReactSVG className='svgContainer' src={textLeft} onClick={ () => {
                  canvas.getActiveObject().set('textAlign', 'left');
                  canvas.renderAll();
                  this.setState({ textAlign: 'left' });
                }}
                style={{ fill: this.state.textAlign === 'left'
                  ? '#B39964' : 'black' }}
                />
                <ReactSVG className='svgContainer' src={textCenter} onClick={ () => {
                  canvas.getActiveObject().set('textAlign', 'center');
                  canvas.renderAll();
                  const newStyle = { ...this.state, textAlign: 'center' };
                  this.props.setStyle(newStyle);
                  this.setState({ textAlign: 'center' });
                }}
                style={{ fill: this.state.textAlign === 'center' ? '#B39964' : 'black' }}
                />
                <ReactSVG className='svgContainer' src={textRight} onClick={ () => {
                  canvas.getActiveObject().set('textAlign', 'right');
                  canvas.renderAll();
                  const newStyle = { ...this.state, textAlign: 'right' };
                  this.props.setStyle(newStyle);
                  this.setState({ textAlign: 'right' });
                }}
                style={{ fill: this.state.textAlign === 'right' ? '#B39964' : 'black' }}
                />
              </div>

            </div>
            <div  className='formating'>
              <span>GESTALTUNG</span>
              <div>
                <ReactSVG className='svgContainer' src={textItalic}
                  onClick={ withoutItalic.includes(this.state.fontFamily) ?
                    null : () => {
                      const { fontFamily } = this.state;
                      const italic = 'italic';
                      if (!fontFamily.includes(italic)) {
                        const newfont = fontFamily + italic;
                        this.setState({ fontFamily: newfont });
                        const newStyle = { ...this.state, fontFamily: newfont };
                        this.props.setStyle(newStyle);
                        var myfont = new FontFaceObserver(newfont);
                        myfont.load()
                          .then(() => {
                            // when font is loaded, use it.
                            canvas.getActiveObject().set('fontFamily', newfont);
                            canvas.requestRenderAll();
                          }).catch(e => {
                            alert('die Schriftart ist nicht in kursiv oder kursiv & fett verfügbar');
                          });
                      } else {
                        const newfont = fontFamily.replace(italic,'');
                        this.setState({ fontFamily: newfont });
                        const newStyle = { ...this.state, fontFamily: newfont };
                        this.props.setStyle(newStyle);
                        // eslint-disable-next-line no-redeclare
                        var myfont = new FontFaceObserver(newfont);
                        myfont.load()
                          .then(() => {
                            canvas.getActiveObject().set('fontFamily', newfont);
                            canvas.requestRenderAll();
                          }).catch(e => {
                            alert('für diese Schriftart wählen Sie entweder kursiv oder fett aus');
                          });
                      }
                    }

                  }
                  style={{ fill: withoutItalic.includes(this.state.fontFamily)
                    ? '#A9A9A9' :
                    this.state.fontFamily.includes('italic') ? '#B39964' : 'black'
                  }}
                />
                <ReactSVG className='svgContainer' src={textBold}
                  onClick={ withoutBold.includes(this.state.fontFamily) ?
                    null :
                    () => {
                      const { fontFamily } = this.state;
                      const bold = 'bold';
                      if (!fontFamily.includes(bold)) {
                        const newfont = bold + fontFamily;
                        this.setState({ fontFamily: newfont });
                        const newStyle = { ...this.state, fontFamily: newfont };
                        this.props.setStyle(newStyle);
                        var myfont = new FontFaceObserver(newfont);
                        myfont.load()
                          .then(() => {
                            // when font is loaded, use it.
                            canvas.getActiveObject().set('fontFamily', newfont);
                            canvas.requestRenderAll();
                          }).catch(e => {
                            alert('für diese Schriftart wählen Sie entweder kursiv oder fett aus');
                          });
                      } else {
                        const newfont = fontFamily.replace(bold,'');
                        this.setState({ fontFamily: newfont });
                        const newStyle = { ...this.state, fontFamily: newfont };
                        this.props.setStyle(newStyle);
                        var myfont = new FontFaceObserver(newfont);
                        myfont.load()
                          .then(() => {
                            // when font is loaded, use it.
                            canvas.getActiveObject().set('fontFamily', newfont);
                            canvas.requestRenderAll();
                          }).catch(e => {
                            alert('die Schriftart ist nicht in fett oder fett & kursiv verfügbar');
                          });
                      }
                    }}
                  style={{ fill: withoutBold.includes(this.state.fontFamily)
                    ? '#A9A9A9' :
                    this.state.fontFamily.includes('bold') ? '#B39964' : 'black'

                  }}
                />
                <ReactSVG className='svgContainer' src={textUnderline}
                  onClick={ () => {
                    if (this.state.textDecoration === false) {
                      canvas.getActiveObject().set('underline', true);
                      canvas.renderAll();
                      this.setState({ textDecoration: true });
                      canvas.getActiveObject().dirty = true;
                    } else {
                      canvas.getActiveObject().set('underline', false);
                      canvas.renderAll();
                      this.setState({ textDecoration: false });
                      canvas.getActiveObject().dirty = false;
                    }
                  } }
                  style={{ fill: this.state.textDecoration === true ? '#B39964' : 'black' }}
                />
              </div>
            </div>
            <div  className='selectContiner'>
              <span>SCHRIFTART</span>

              <select className='slectContainer'
                value={this.findFontfamilyvalue()}
                style={{ fontFamily: this.state.fontFamily }}
                onChange={ e => {
                  const font =  e.target.value;
                  var myfont = new FontFaceObserver(font);

                  myfont.load()
                    .then(() => {
                      // when font is loaded, use it.
                      canvas.getActiveObject().set('fontFamily', font);
                      canvas.requestRenderAll();
                    }).catch(e => {
                      console.log(e)
                      if (canvas.getActiveObject() == null) {
                        alert('Bitte zuerst ein Textfeld auswählen / erstellen');
                      } else {
                        alert('Leider ist ein Fehler aufgetreten');
                      }
                    });
                    this.setState({ fontFamily: e.target.value });
                }} >
                <option value="Abel">Abel</option>
                <option value="Audiowide">Audiowide</option>
                <option value="Bitter">Bitter</option>
                <option value="CarterOne">Carter One</option>
                <option value="Cinzel">Cinzel</option>
                <option value="Graduate">Graduate</option>
                <option value="GreatVibes">GreatVibes</option>
                <option value="Kameron">Kameron</option>
                <option value="LobsterTwo">LobsterTwo</option>
                <option value="Michroma">Michroma</option>
                <option value="Monda">Monda</option>
                <option value="Niconne">Niconne</option>
                <option value="OleoScript">OleoScript</option>
                <option value="Play">Play</option>
                <option value="PlayfairDisplay">PlayfairDisplay</option>
                <option value="PTSans">PTSans</option>
                <option value="Raleway">Raleway</option>
                <option value="RobotoSlab">RobotoSlab</option>
                <option value="Teko">Teko</option>
                <option value="VastShadow">VastShadow</option>
              </select>
            </div>
          </div>

          <div className='sendButton'>
            <span onClick={() => this.props.addEngraving([addFigure, 'Meine Gravur'])} >Textfeld einfügen</span>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => {
  const { frontCurrent, backCurrent } = state.currentFigureReducer;
  const { style } = state.styleReducer;
  const { sideIs } = state.optionsReducer;
  return {
    frontCurrent, style, sideIs, backCurrent
  };
};
const mapDispatchToProps = dispatch => ({
  addEngraving: data => dispatch(addEngraving(data)),
  setStyle: fs => dispatch(setStyle(fs))
});

export default connect(mapStateToProps, mapDispatchToProps)(TextOptions);
