/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable default-case */
/* eslint-disable no-unused-expressions */

import { Fonts } from './Fonts';
import { fabric } from 'fabric';
import axios from 'axios';


fabric.textureSize = 4096;
fabric.Object.prototype.objectCaching = false;


function getBase64(url) {
  return axios.get(url, {
    responseType: 'arraybuffer'
  })
    .then(response => Buffer.from(response.data, 'binary').toString('base64'));
};


function getSvgStyle(fontFamiliy, base64Font) {
  return `
   @font-face {
       font-family: '${fontFamiliy}';
       src: url("data:font/ttf;charset=utf-8;base64,${base64Font}");
   }`;
};

function callBackend(state, props, frontSideSVG, backSideSVG, totalPrice ) {
  const { woo_id, id } = props.product;
  const {  userImages } = state;
  const { orginalPics } = props;
  const fd = new FormData();
  if (orginalPics[1] && orginalPics[1].length > 0) {
    var uniqSVg = [...new Set(orginalPics[1])];
    uniqSVg.forEach( (svg, i) => {
      fd.append(`customer_svg[${i}]`, svg );
    });
  }
  var imageFilter = [...new Set(userImages)];
  fd.append('woo_id' , woo_id);
  fd.append('final_price', totalPrice);
  fd.append('product_id' , id );
  fd.append('frontSideSVG' , frontSideSVG);
  fd.append('backSideSVG' , backSideSVG);
  imageFilter.forEach( (img, i) => {
    fd.append(`customer_images[${i}]`, img );
  });

  axios({
    method: 'post',
    url: '/orders/index',
    data: fd,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(response => {
    console.log(response)
    if (response.error) {
      alert('Leider ist ein Fehler aufgetreten');
    } else {
      window.location.href = response.data.redirect;
    }
  }).catch(error => {
    if (error.response) {
      alert('Leider ist ein Fehler aufgetreten: ' + error.response.data.message);
    } else {
      alert('Leider ist ein Fehler aufgetreten');
    }
  });
};

export function AddToChart(state, props, totalPrice) {
  let frontSideSVG , backSideSVG, canvasFonts;
  props.setSide('front');
  if (props.frontCurrent.getObjects().length > 1 ) {
    const selectedCanvas = props.frontCurrent;
    const side = props.product.workArea.front;
    handleCanvas(selectedCanvas, side).then( () => {
      const svgString = props.frontCurrent.toSVG();
      Promise.all(canvasFonts.map( fontName => getBase64(Fonts[fontName]).then(base64font => [fontName, base64font]))).then(downloadedFonts => {
        const svgStyles = downloadedFonts.map(([fontName, font64]) => getSvgStyle(fontName, font64));
        const rawStyle = svgStyles.join('\n');
        var Frontsvg = svgString.replace(/(<svg)([^<]*|[^>]*)/g,match => {
          return match + '<g><style>'+ rawStyle + '</style>';
        }
        );
        var addBorder = Frontsvg.replace(/(<\/svg)([^<]*|[^>]*)/g,match => {
          return `
            <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="0" y1="0" x2="50" y2="0" />
            <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="0" y1="0" x2="0" y2="50" />
            <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="620" y1="652" x2="670" y2="652" />
            <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="670" y1="602" x2="670" y2="652" />
          </g>` + match;
        }
        );
        frontSideSVG = addBorder;
        if (state.hasBackside) {
          checkBackSide();
        } else {
          callBackend(state, props, frontSideSVG, backSideSVG, totalPrice);
        }
      });
    });
  } else {
    checkBackSide();
  }

  function checkBackSide() {
    props.setSide('back');
    if (props.backCurrent.getObjects().length > 1) {
      const selectedCanvas = props.backCurrent;
      const side = props.product.workArea.back;
      handleCanvas(selectedCanvas, side).then( () => {
        const svgString = props.backCurrent.toSVG();
        Promise.all(canvasFonts.map( fontName => getBase64(Fonts[fontName]).then(base64font => [fontName, base64font]))).then(downloadedFonts => {
          const svgStyles = downloadedFonts.map(([fontName, font64]) => getSvgStyle(fontName, font64));
          const rawStyle = svgStyles.join('\n');
          var backSVG = svgString.replace(/(<svg)([^<]*|[^>]*)/g,match => {
            return match + '<g><style>'+ rawStyle + '</style>';
          }
          );
          var addBorder = backSVG.replace(/(<\/svg)([^<]*|[^>]*)/g,match => {
            return `
                <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="0" y1="0" x2="50" y2="0" />
                <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="0" y1="0" x2="0" y2="50" />
                <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="620" y1="652" x2="670" y2="652" />
                <line style="stroke: rgb(255,0,0); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="670" y1="602" x2="670" y2="652" />
              </g>` + match;
          }
          );
          backSideSVG = addBorder;
          callBackend(state, props, frontSideSVG, backSideSVG, totalPrice);
        });
      });
    } else {
      callBackend(state, props, frontSideSVG, backSideSVG, totalPrice);
    }
  }

  async function handleCanvas(selectedCanvas, side) {
    const allFonts = new Set();
    selectedCanvas.backgroundImage = 0;
    selectedCanvas.discardActiveObject();
    let left , top, width, height;
    if (props.product.workArea) {
      left = side.left;
      top = side.top;
      width = side.width;
      height  = side.height;
    } else {
      // eslint-disable-next-line no-sequences
      left , top = 0;
      width = 670; // defaulut image size from welovelaser
      height = 652; // /////////////////////////////////
    }
    var out_frame = new fabric.Rect({
      width: width,
      height: height,
      top: top,
      left: left,
      absolutePositioned: true
    });
    selectedCanvas.clipPath = out_frame;
    const allObjectOnCanvas = selectedCanvas.getObjects();
    const collect = [];
    allObjectOnCanvas.map( o => {
      if (o.text) {
        allFonts.add(o.fontFamily);
        o.clipPath = null;
        if (o._objects) {
          o._objects.map( obj => {
            obj.fill = 'black';
          });
        } else {
          o.fill = 'black';
        }
        collect.push(o);
      } else if (o.type === 'rect') {
        selectedCanvas.remove(o);
      }  else if (o.id === 'svg') {
        o.clipPath = null;
        if (o._objects) {
          o._objects.map( obj => {
            if ( obj.fill.length > 0 ) {
              obj.fill = 'black';
            }
            if (obj.stroke.length > 0 ) {
              obj.stroke = 'black';
            }
          });
        } else {
          o.fill = 'black';
        }
        collect.push(o);
      }  else {
        // empty text !!
        o.clipPath = null;
        const { orginalPics } = props;
        const { userImages } = state;
        orginalPics[0].map( file => {
          file.name === o.id ? userImages.push(file) : null;
        });
        var filter = new fabric.Image.filters.Brightness({ brightness: -1 });
        o.filters.push(filter);
        o.applyFilters();
        collect.push(o);
      }
    }
    );
    const group = fabric.util.groupSVGElements(collect);
    selectedCanvas.remove(...selectedCanvas.getObjects());
    selectedCanvas.add(group);
    canvasFonts = Array.from(allFonts);
  }
}
