/* eslint-disable prefer-destructuring */
import React , { Component } from 'react';
import uploadIcon from '../Logos/upload.svg';
import { connect } from 'react-redux';
import { addEngraving, addoriginalPics, acceptTerms, imageOrtext } from '../actions';
// import { SVG } from '@svgdotjs/svg.js';
// import Filter from '@svgdotjs/svg.filter.js/src/svg.filter';
// import Filter from  '@svgdotjs/svg.filter.js';  //3.0.2
import { ReactSVG } from 'react-svg';


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

class ImageOptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      range: 145,
      userImages: [],
      invert: true,
      showCanvas: false,
      invertedImageUrl: null,
      userSVG: [],
      color: [this.props.product.engColor.r,this.props.product.engColor.g,this.props.product.engColor.b]
    };
    this.canvas = React.createRef();
    this.image = React.createRef();
  }

    checkImage = e => {
      if (e.target.files[0] &&
         e.target.files[0].type.includes('jpeg') ||
         e.target.files[0].type.includes('jpg') ||
         e.target.files[0].type.includes('png')) {
        const { userImages } = this.state;
        userImages.push(e.target.files[0]);
        const img = new Image();
        img.id = e.target.files[0].name;
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if(img.width > 599 && img.height > 599 && img.width < 2501 && img.height < 2501) {
            this.image.current = img;
            this.setState({ showCanvas: true, userImages });
            this.invertColor();
          } else  alert('Die Datei kann nicht hochgeladen werden. Bitte beachten Sie die Dateivoraussetzungen.');
        };
      } else if ( e.target.files[0].type.includes('svg') ) {
        const { userSVG } = this.state;
        const reader = new FileReader();
        reader.addEventListener('load', event => {
          const invertedImageUrl = { url: event.target.result };
          userSVG.push(event.target.result);
          this.setState({ invertedImageUrl, userSVG  });
          let  addFigure;
          if (this.props.sideIs === 'front') {
            addFigure = 'FRONT_ADD_FIGURE';
          } else addFigure = 'BACK_ADD_FIGURE';
          this.props.addoriginalPics([this.state.userImages, this.state.userSVG]);
          this.props.addEngraving([addFigure, invertedImageUrl]);
        });
        reader.readAsText(e.target.files[0]);
      } else {
        alert('Laden Sie Bitte ein Bild hoch');
      }
    }

    reInverColor = e => {
      let value;
      if (e) {
        value = e.target.value;
      } else value = this.state.range;
      const img = this.image.current;
      const { color } = this.state;
      const canvas = this.canvas.current;
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.id = img.id;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var imgData = ctx.getImageData(0, 0,  img.width, img.height);
      var i;
      for (i = 0; i < imgData.data.length; i += 4) {
        if (imgData.data[i] < value || imgData.data[i+1] < value || imgData.data[i+2] < value) {
          imgData.data[i+3] = 0;
        } else {
          imgData.data[i] = color[0];
          imgData.data[i+1] = color[1];
          imgData.data[i+2] = color[2];
          imgData.data[i+3] = 255;
        }
      }
      ctx.putImageData(imgData, 0, 0);
      const invertedImageUrl = { url: canvas.toDataURL(), id: canvas.id };
      this.setState({ invertedImageUrl  });
    }

    invertColor = e => {
      let value;
      if (e) {
        value = e.target.value;
      } else value = this.state.range;
      const img = this.image.current;
      const { color } = this.state;
      const canvas = this.canvas.current;
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.id = img.id;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var imgData = ctx.getImageData(0, 0,  img.width, img.height);
      var i;

      for (i = 0; i < imgData.data.length; i += 4) {
        if (imgData.data[i] > value || imgData.data[i+1] > value  || imgData.data[i+2] > value) {
          imgData.data[i+3] = 0;
        } else {
          imgData.data[i] = color[0];
          imgData.data[i+1] = color[1];
          imgData.data[i+2] = color[2];
          imgData.data[i+3] = 255;
        }
      }
      ctx.putImageData(imgData, 0, 0);
      const invertedImageUrl = { url: canvas.toDataURL(), id: canvas.id };
      this.setState({ invertedImageUrl });
    }

    checkColor(state) {
      const color  = state.color
      if (color[0] > 200 && color[1] > 200 && color[2] > 200) {
        return false
      } else {
        return true
      }
    };

    render() {
      let  addFigure;
      if (this.props.sideIs === 'front') {
        addFigure = 'FRONT_ADD_FIGURE';
      } else addFigure = 'BACK_ADD_FIGURE';
      return (
        <div className='image-options'>
          <div  className='accepted' style={{
            display: this.props.userAccept? '' : 'none'
          }}>
            <div className='hide-content-button' onClick={ () =>
              document.getElementById('content').style.zIndex = '1' }>X</div>
            <div className= 'hint'>
              <div className='uploadArea'>
                <span>akzeptierte Dateiformate: jpg, png, jpeg, svg</span>
                <span>Mindestabmessungen: 600x600 Pixel</span>
                <span>Maximalabmessungen: 2500x2500 Pixel</span>
              </div>
              <div>
                <label htmlFor="image-upload" className="custom-file-upload">
                  <ReactSVG src={uploadIcon}   />
                  <p>Bilder Hochladen</p>
                </label>
                <input id='image-upload' type="file" onChange={ e => this.checkImage(e)}
                  onClick={ event =>   event.target.value = null }/>
              </div>
              {this.state.showCanvas?  <canvas ref={this.canvas}  className={this.checkColor(this.state)? 'canvasContiner white':'canvasContiner black'}/>  : null }
              {this.state.showCanvas? <div className= 'contrast-area'>
                <span>Kontrast</span>
                <input
                  type="range"
                  value={this.state.value}
                  min="0" max="255"
                  onChange = { e => {
                    if (this.state.invert === true) {
                      this.invertColor(e);
                    } else this.reInverColor(e);
                    this.setState({ range: e.target.value });
                  }}
                  step="1"/>
              </div> : null }
              <div className='sendButton'>

                {this.state.showCanvas? <span onClick={() => {
                  if (this.state.invert === true) {
                    this.reInverColor();
                    this.setState({ invert: !this.state.invert });
                  } else {
                    this.invertColor();
                  }
                  this.setState({ invert: !this.state.invert });
                }} >Invertieren</span> : null }
                {this.state.showCanvas? <span onClick={() => {
                  this.setState({ showCanvas: false });
                }} >Löschen</span> : null }
                {this.state.showCanvas? <span onClick={() => {
                  this.props.addoriginalPics([this.state.userImages, this.state.userSVG]);
                  this.props.addEngraving([addFigure, this.state.invertedImageUrl]);
                  this.props.imageOrtext('Text');
                  document.getElementById('content').style.zIndex = '1';
                }
                }>Hinzufügen</span> : null }
              </div>
            </div>
          </div>
          <div className='refused' style={{
            display: !this.props.userAccept? '' : 'none'
          }}>
            <span>akzeptierte Dateiformate: jpg, png, jpeg, svg</span>
            <span>Mindestabmessungen: 600x600 Pixel</span>
            <span>Maximalabmessungen: 2500x2500 Pixel</span>
            <span>
                    Ich habe das Recht, das Bild für kommerzielle Zwecke zu
                    verwenden und verletze keine Rechte Dritter mit der Nutzung des Bildes.
            </span>
            <span>
                    Im Fall einer Rechtsverletzung wird WeLoveLaser den Rechteinhaber mit sämtlichen Forderungen
                    an mich verweisen.
            </span>
            <span>
                    Mir ist bekannt, dass die unrechtmäßige Verwendung, von durch Dritte geschützen Motiven,
                    kein Kavaliersdelikt ist und mit hohen Geldstrafen geahndet werden kann.
            </span>
            <span>
                    Wir behalten uns vor die Produktion von gestalteten Produkten, die offensichtlich markenrechtlich
                    relevante Bestandteile beinhalten, abzulehnen.
            </span>
            <div className={isMobile? 'mobileButton' : 'sendButton'} onClick={() => this.props.acceptTerms(true)}>
              <label htmlFor="image-upload">
                 Akzeptieren
              </label>
              <input id='image-upload' type="file" onChange={ e => this.checkImage(e)}/>
            </div>
          </div>
        </div>
      );
    }
}
const mapStateToProps = state => {
  const { sideIs, userAccept } = state.optionsReducer;
  const { product } = state.productReducer;

  return {
    sideIs, userAccept, product
  };
};

const mapDispatchToProps = dispatch => ({
  acceptTerms: yes => dispatch(acceptTerms(yes)),
  addEngraving: data => dispatch(addEngraving(data)),
  addoriginalPics: pics => dispatch(addoriginalPics(pics)),
  imageOrtext: data => dispatch(imageOrtext(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(ImageOptions);
